import { useState,useEffect } from "react";
import React from 'react';
import ReactDatetime from "react-datetime";
import { BrowserRouter, Route, Switch, Redirect, useHistory,Link,useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover 
} from "reactstrap";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import {reportmanageService} from '../../../services/reportmanageService';
import { taskmanageService } from "../../../services/taskmanageService";
import '../../../assets/css/custom.css';
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";

 const RejectionReport = (props) => {
 const params  = useParams();
 const history = useHistory();
 const [reportRejectiondata, setReportRejectionData] = useState([]);
 const [currentPage, setCurrentPage] = useState(null);
 const [last_page, setLastPageData] = useState(0);
 const [error, setError] = useState(null)
 const [statuslist, setStatusdata] = useState([]);
  const [successMsg, setsuccessMsg] = useState(null)
  const [hoddatatype,sethoddatatype] = useState('qa');
  const [filterlist, setfilterlist] = useState([]);
  const [showfilterbox,setshowfilterbox] = useState(false);
  const [filterApplyFields,setfilterApplyFields] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const [filtertype,setfiltertype] = useState('');
  const [phaselist, setphaselist] = useState([]);
  
  const errorDiv = error 
        ? <UncontrolledAlert className="alert-danger" fade={false}>
        {error}
        </UncontrolledAlert> 
        : '';
  const successDiv = successMsg 
        ? <UncontrolledAlert className="alert-success" fade={false}>
        {successMsg}
        </UncontrolledAlert> 
        : '';
  useEffect(()=>{
    get_status_list();
    getrejection_list(1,null,hoddatatype)
  },[]);
  const [sortfield,setsortfield] = useState('');
  const [sortorder,setsortorder] = useState('asc');
  function get_status_list() {
    taskmanageService.getstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  function sortTable(field){
    console.log(field)
    setsortfield(field);
    if(sortorder=='asc'){
      setsortorder('desc');
    }else{
      setsortorder('asc');
    }
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getrejection_list(index,null,hoddatatype);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getrejection_list(prev,null,hoddatatype);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getrejection_list(next,null,hoddatatype);
  };
  const getrejection_list = async (pageNumber = 1,e,hoddatatype,filterFields,filtertype='') => { 
    
    reportmanageService.getReportRejections(pageNumber,filterFields,filtertype='',hoddatatype).then((response) => {
      if(response.status == 200){
         setReportRejectionData(response.data.data.data);
         setphaselist(response.data.phaselist) 
         setCurrentPage(response.data.data.current_page);
         setLastPageData(response.data.data.last_page);
      }
         
    });
  };
  const handlerejeditRow = (index) => {
    // if(phaseedit == true || taskedit == true || analystedit == true){
      let temp_state = [...reportRejectiondata];
      let temp_element = { ...temp_state[index] };
      temp_element.editing = true;
      temp_state[index] = temp_element;
      console.log(temp_state)
      setReportRejectionData(temp_state);
      // }

  };
  function downloadReport(){

    const cloneValues = {...phasestate};
  
        if(statesend.startDate !=null && statesend.endDate !=null){
        cloneValues[0] = {
          ...cloneValues[0],
          ['op_name']: 'between',
          ['operator']: 'between',
          ['field'] : 'date',
          ['value'] : statesend.startDate + ' to ' + statesend.endDate
        };
      }
    
    reportmanageService.download_rejection_report(cloneValues,filtertype,hoddatatype).then((response) => {
    
    });
  }
  const editRejField = (value, field, id,index) => {
    taskmanageService.editRejectionRow(value, field, id).then((response) => {
      let temp_st = [...reportRejectiondata];
      let temp_ele = { ...temp_st[index] };
      if(response.status == 200){
        toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.editing = false;
      temp_ele[field] = value;
      if (field == "status") {
        Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
          temp_ele['status_name'] = v.name;
       }, {});
       }
       if(field=='category_id'){
        console.log(value)
        const category = temp_ele.category.find(cat => cat.id == value);
        temp_ele['category_name'] = category.name;
       
       }
       if(field=='type'){
        temp_ele['error_type'] = value;
       }
      temp_st[index] = temp_ele;
      setReportRejectionData(temp_st);
    })
  }

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  
  const [taskdetails,settaskdetails] = useState({});
  const [taskdetailPopup,settaskdetailPopup] = useState(false);
  
  const taskDetail = (task_id,index) => {
    taskmanageService.gettaskdetail(task_id,'','').then((response) => {
      settaskdetails(response.data.data);
      settaskdetailPopup(!taskdetailPopup);
    })
  }
  const downloadfile = (path,name,extension) => {
    taskmanageService.download_file(path,name,extension).then((response) => {
    
    });
  }
  function switchToType() {
    if(hoddatatype == 'qa'){
      sethoddatatype('phase')
      getrejection_list(1,null,'phase');
    }else{
      sethoddatatype('qa')
      getrejection_list(1,null,'qa');
    }
  };
  const getFilterList = () => {
    taskmanageService.getinternalccrpfilterlist().then((response) => {
      setfilterlist(response.data.filter);
      setshowfilterbox(!showfilterbox);
    })
  }
  const applyFilter = (e='') => {
    getrejection_list(1,null,hoddatatype,filterFields,filtertype);
    if(e==''){
      setfilterApplyFields(filterFields);
      document.getElementById('tooltipfilter').click();
    }else{
      setfilterApplyFields(filterFields);
    }
  }
  const removefromFilter = (index) => {
    let state = {...filterFields};
    console.log(state)
    Object.entries(state) // converts each entry to [key, value]
      .filter(([k, v]) => v.field == index) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
         delete state[k];
         setfilterFields(state);
         setfilterApplyFields(state);
      }, {});
      
      applyFilter(1);
      //document.getElementById('tooltipfilter').click();
  }

 
  function filterInputHandler(e,index,op_name='') {
  const cloneValues = {...filterFields};
    if(op_name!=''){
      cloneValues[index] = {
        ...cloneValues[index],
        ['op_name']: op_name,
      };
    }
    var index1 = e.target.selectedIndex;
    if(index1){
      cloneValues[index] = {
        ...cloneValues[index],
        ['val_name']: e.target[index1].text,
      };
    }
    cloneValues[index] = {
      ...cloneValues[index],
      [e.target.name]: e.target.value,
    };

    console.log('filter data');
    console.log(cloneValues);
    setfilterFields(cloneValues);
  
  }
  function filterClickHandler(name,value,index) {
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      [name]: value,
    };
    setfilterFields(cloneValues);
    // console.log(filterFields)
  }
  function setEndDateRange(e,index,field){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
  
    const cloneValues = {...filterFields};
     
        cloneValues[index] = {
          ...cloneValues[index],
          ['op_name']: 'between',
          ['field'] : field,
          ['value'] : statesend.startDate + ' to ' + dd
        };
    
    
      console.log('filter data');
      console.log(cloneValues);
      setfilterFields(cloneValues);
  }
  const [datestate, setcustomdateState] = useState({});
  function setEndDateRangeCustom(e,index,field){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
  
    const cloneValues = {...phasestate};
     
        cloneValues[index] = {
          ...cloneValues[index],
          ['op_name']: 'between',
          ['operator']: 'between',
          ['field'] : field,
          ['value'] : statesend.startDate + ' to ' + dd
        };
    
    
      setcustomdateState(cloneValues);
      getrejection_list(1, null, hoddatatype, cloneValues);
  }
const [state, setState] = useState({startDate:null,endDate:null});
const [statesend, setStatesend] = useState({startDate:null,endDate:null});
const [phasestate, setphaseState] = useState({});
  function setStartDateRange(e,index){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
  }
  const companyfilter = (e,index,field) => {
    
    const cloneValues = {
      ...phasestate,
      [index]: {
        ...phasestate[index],
        field: e.target.name,
        value: e.target.value,
        operator: '='
      }
    };
    setphaseState(cloneValues);
    console.log(cloneValues);
    if(e.target.value=='qa'){
      sethoddatatype('phase')
      getrejection_list(1, null, 'phase', cloneValues);
    }else{
    getrejection_list(1, null, hoddatatype, cloneValues);
    }
    
  }
  function deletedata(e,id){

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        executeAction();
      }else{}
    });

  const executeAction = () => {
 
    taskmanageService.deleterejection(id).then((response)=>{
    if(response.status == 200){
       toast.success(response.data.message); 
       getrejection_list(1,null,hoddatatype)
    }else{
      console.log(response.data);
       toast.error(response.data.message);
    }
    
  });
  };
}
const [clientsearch, setclientsearch] = useState('');
function client_search(e,index){
  setclientsearch(e.target.value);
  const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: e.target.name,
      ['value']: e.target.value,
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getrejection_list(1, null, hoddatatype, cloneValues);

}
return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className="row">
            <div className="col-xl-12">
           
            <Row>
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="filter" style={{padding:'0 1%'}}>
                      <div className="filter">
                        <h4 className="text-uppercase text-muted ls-1 mb-1">
                          Report
                        </h4>
                        <div style={{'margin-left':'20px'}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center',marginRight:'10px'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-12">
                      
                  
                        
                              <select className="form-control" name="phase" onChange={(e) => { companyfilter(e,3,'phase');}}   >
                                  <option value="" key="al_key_1">Select Phase</option>
                                  {
                                  (phaselist) ? 
                                  Object.entries(phaselist).map(([valuel,k]) =>
                                      <option key={k} value={valuel}>
                                        {k}
                                      </option>
                                      
                                  )
                                :''
                                }
                                  <option value="qa">QA</option>
                              </select>
                            
                        </div>
                        
             
                   
    
                  
                        <div className="col-xl-3 col-md-3 col-xs-12">
                        
                        <FormGroup style={{'margin-bottom':0}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup >
                        </div>
                        <div className="col-xl-3 col-md-3 col-xs-12">
                        
                        <FormGroup style={{'margin-bottom':0}}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRangeCustom(e,0,'date')}
                          />
                        </InputGroup>
                      </FormGroup>
                        </div>
                        
                      </div>
                      {/* {user_info.role_id == 14 ?
                      <Col xl="2">
                      <Button
                        className="col-12 phase-button qatl-main-btn qatl-main-btn-sec"
                        color="light"
                        type="button"
                        onClick={() => switchToType()}
                      >
                        {(hoddatatype == 'phase') ? 'Switch To QA' : 'Switch to POD' }
                      </Button>{" "} </Col>
                      : null } */}
                   
                      
                    
                   
                    </Row>
                    
                   
                <Row style={{paddingLeft:'1%',textAlign:'right'}}>
                <div className="col-xl-2 col-md-2 col-xs-12">
                        <FormGroup style={{'margin-top':'10px'}}>
                        <Input placeholder="Search client..." id="companydata" name="company" className="" type="text" style={{fontWeight:"600",color:"black",width:'200px'}}
                onKeyUp={(e) =>client_search(e,2)}  
                /></FormGroup>
                          </div>
                <Col xl="10">
                      
                      <button  onClick={(e) => getFilterList()}  type="button" className="btn-icon-clipboard" data-clipboard-text="bold-down" title="Copy to clipboard" style={{padding: 10,width:'130px'}}>
                        <div>
                          <img alt="..." src={require("../../../assets/img/brand/filter.png")} style={{height:15, width:15}}/>
                          <span style={{"fontSize":16,fontFamily:"inter"}}>Filters</span>&nbsp;
                          {(showfilterbox) ? (<i className="ni ni-bold-up"></i>) : 
                            (<i className="ni ni-bold-down"></i>)}
                        </div>
                      </button>
                   </Col>
                    {(showfilterbox== true)?
                  <Col xl="1">
                   {(filterApplyFields) ?
                    (Object.values(filterApplyFields)).map((t,k) => (
                        <button key={k} className="btn btn-icon btn-3 btn-primary filter-button" type="button" style={{color:'#3F3F3F'}}>
                          <span className="btn-inner--text"><b>{t.field} #</b> {t.op_name} {(t.val_name)?t.val_name:t.value}</span>
                          <span className="btn-inner--icon" onClick={() => removefromFilter(t.field)}><i className="ni ni-fat-remove"></i></span>
                        </button>
                      )):null} 
                    <button style={{color:'#3F3F3F'}} type="button" className="btn btn-icon btn-primary filter-button" data-placement="right" id="tooltipfilter" >
                        +
                    </button>
                    {(filterlist.length > 0) ?
                    (<UncontrolledPopover placement="right" target="tooltipfilter">
                      <PopoverBody>
                      {filterlist.map((filter, i) => (
                      <React.Fragment key={i}>
                      <p key="para" data-placement="right" id={`tooltipraised-${i}`} style={{cursor:"pointer"}} name="field" value={filter.field} onClick={(e) => {filterClickHandler('field',filter.field,i),setfiltertype('')}}>{filter.field}</p>
                      {/* {(filter.values.length > 0)? */}
                      <UncontrolledPopover key={i} placement="right"  target={`tooltipraised-${i}`}>
                      <PopoverBody>
                         <Form>
                         {(filter.operators).map((operator,j) => (
                         <div key={j} className="custom-control custom-radio mb-3" style={{paddingLeft:'1rem'}}>
                          <input id="customRadio5"  value={operator.symbol} name="operator" type="radio" onChange={(e) => filterInputHandler(e,i,operator.value)} />
                          {/* <label className="custom-control-label" htmlFor="customRadio5"> */}
                           &nbsp;&nbsp; {operator.value}
                          {/* </label> */}
                        </div>
                         )) }
                          <div id="textboxes" >
                        {(filter.values.length > 0)?
                          <select className="form-control" name="value" onChange={(e) => filterInputHandler(e,i)}>
                              <option value="" key="al_key_1">Select values</option>
                              {(filter.values).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                        : (filter.type == 'daterange') ?  
                        <>
                        <Col xs={12}>
                        <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e,i)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e,i,filter.field)}
                          />
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      </>
                        :
                        <input className="form-control" type={filter.type} name="value" onChange={(e) => filterInputHandler(e,i)} />
                        }
                        <br></br>
                        <button type="button" className="col-8 dash-button btn btn-primary" onClick={(e) => applyFilter()} > Apply </button>
                        {/* <button type="button" className="col-6 phase-button  btn btn-light">Cancel</button> */}
                         </div>
                         </Form>
                      </PopoverBody>
                    </UncontrolledPopover>
                       {/* :null }  */}
                     </React.Fragment>
                      ))}
                        </PopoverBody>
                    </UncontrolledPopover>):null }   
                  
                  </Col> 
                  : null } 
                </Row> 
                  </CardHeader>
                  <Table className="align-items-center table-flush table-resp" >
                  <thead className="thead-light thead-fixed">
                
                    <th scope="col" className="whitespace" >Client Name</th>
                    <th scope="col" className="whitespace">Sub Client</th>
                    <th scope="col" className="whitespace">Actionable</th>
                    {/* <th scope="col" className="whitespace">Rejection For</th> */}
                    <th scope="col" className="whitespace">Phase name</th>
                    <th scope="col" className="whitespace">Category</th>
                    <th scope="col" className="whitespace">Type</th>
                    <th className="whitespace">No. of Errors</th>
                    <th scope="col" className="whitespace">Amount</th>
                    <th className="whitespace">Feedback</th>
                    <th className="whitespace">Root cause</th>
                    <th className="whitespace">Action plan</th>
                    <th scope="col">Date</th>
                    <th scope="col" className="whitespace">Error raised by</th>
                    <th className="whitespace" scope="col">Doer</th>
                    <th scope="col" className="whitespace">Status</th>
                    <th className="whitespace" scope="col">Document</th>
                    {user_info.role_id == 2 || user_info.role_id==3 || user_info.role_id==11 || user_info.role_id==14 ?
                    <th className="whitespace" scope="col">Action</th> : ""}
                    
                </thead>
                <tbody>
                  {reportRejectiondata &&
                    reportRejectiondata.length &&
                    reportRejectiondata != null ? (
                      reportRejectiondata.map((item, i) => (
                      <>
                      <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } edit-type={item.edittype} edit-id={item.id}>
                          <th scope="row" onClick={() => taskDetail(item.task_id, i)}  className="whitespace">
                            <span className="mb-0 text-sm"  style={{cursor:'pointer'}}  >
                              {item.name} ({item.type}) {" "} &nbsp;&nbsp;
                            </span>
                          </th>
                          
                          <td className="whitespace">{item.sub_client}</td>
                          <td className="whitespace">{(item.actionable == 1) ? 'Yes' : 'No'}</td>
                          {/* <td className="whitespace">{item.rej_for}</td> */}
                          <td className="whitespace">{item.phase_name}</td>
                          {/* <td className="whitespace">{item.category_name}</td> */}
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "category_id",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.category_id}
                              >
                                <option value="0" key="0" >Select Category</option>
                                {item.category &&
                                item.category.length &&
                                item.category != null ? (
                                  item.category.map((cat) =>
                                  
                                      <option key={cat.id} value={cat.id} >
                                        {cat.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{item.category_name}</p>
                              )} 
                              </td>  
                              <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                          {item.editing  ? (
                             <select
                               className="form-control"
                               onChange={(e) =>
                                editRejField(
                                   e.target.value,
                                   'type',
                                   item.id,
                                   i,e
                                 )
                               }
                               defaultValue={item.error_type}
                             >
                               <option value="">Select</option>
                               <option value="Fatal">Fatal</option>
                                <option value="Non-Fatal">Non-Fatal</option>
                             </select>
                           ) : (item.error_type!=null)?item.error_type:''}
                         </td>
                   
                         <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing && user_info.role_id==14  ? (
                          <textarea type="number"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "no_error",
                                item.id,
                                i
                              )
                            }
                          >{item.no_error}</textarea>
                        ) : (
                          <span>{item.no_error}</span>
                        )}
                      </td>
                      <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing && user_info.role_id==14  ? (
                          <textarea type="number"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "amount",
                                item.id,
                                i
                              )
                            }
                          >{item.amount}</textarea>
                        ) : (
                          <span>{item.amount}</span>
                        )}
                      </td>
                          
                          <td className="whitespace">{item.comment}</td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "root_cause",
                                item.id,
                                i
                              )
                            }
                          >{item.root_cause}</textarea>
                        ) : (
                          <span>{item.root_cause}</span>
                        )}
                      </td>
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)} >
                          {item.editing  ? (
                          <textarea type="text"
                          className="form-control"
                            onBlur={(e) =>
                              editRejField(
                                e.target.value,
                                "action_plan",
                                item.id,
                                i
                              )
                            }
                          >{item.action_plan}</textarea>
                        ) : (
                          <span>{item.action_plan}</span>
                        )}
                      </td>
                          <td className="whitespace">{item.date}</td>
                          <td className="whitespace">{item.lead}</td>
                          <td className="whitespace">{item.member}</td> 
                          <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {item.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    item.id,
                                    i
                                  )
                                }
                                defaultValue={item.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{item.status_name}</p>
                              )} 
                              </td>  
                              <td className="whitespace">
                                {(item.document != null) ?
                                <a style={{color:"green"}} onClick={() => downloadfile(item.document,item.file_name,item.extension)} download><i className="fa fa-download"></i> Download</a>
                                : null }
                                </td>
                                {user_info.role_id == 2 || user_info.role_id==3 || user_info.role_id==11 || user_info.role_id==14 ?
                                <td className="whitespace"><button  className="btn btn-danger btn-round btn-icon"  color="primary"  onClick={(e) =>deletedata(e,item.id)}>
                                <i className="fa fa-trash"></i>
                            </button></td> : ""}         
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
                    </Table>
                    <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
                </Card>
              </Col>
             
            </Row>
             
            </div>
        </div>

        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
        </Row>
      </Container>
    </>
  );
};
export default RejectionReport;
